import React from "react"
import {
  createStyles,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  Fab,
  ButtonBase,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import format from "date-fns/format"
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp"
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp"
import { Helmet } from "react-helmet"

import { MarkdownRenderers } from "../utils/helpers"

import ReactMarkdown from "react-markdown"
import { graphql, Link, PageProps, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import TagList from "../components/tag-list"
import { Tag } from "../utils/types"

const DateFnsFormat = format

interface BlogPrevNext {
  title: string
  path: string
  date: string
}

const DATE_FORMAT = "EEEE, LLLL d, y"
const TIME_FORMAT = "h:mma"

const NAV_DATE_FORMAT = "LLL d, yyy"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    navigation: {
      marginTop: theme.spacing(1.5),
    },
    blogNav: {
      padding: theme.spacing(1),
    },
    prevLink: {
      textAlign: "right",
    },
    prevLinkContainer: {
      textAlign: "right",
    },
  })
)

interface BlogProps extends PageProps {
  pageContext: {
    blog: any
  }
}

export default function BlogView(props: BlogProps) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { blog } = props.pageContext
  const { title, SK, body, tags, prev, next, blurb } = blog

  const publishedDate = new Date(SK)

  const classes = useStyles()

  const blogNavLink = (blog: BlogPrevNext, arrowPos: string) => {
    const arrowIcon =
      arrowPos === "after" ? (
        <ArrowForwardIosSharpIcon fontSize="small" />
      ) : (
        <ArrowBackIosSharpIcon fontSize="small" />
      )

    const arrowBtn = (
      <Grid item>
        <Fab size="small" color="primary">
          {arrowIcon}
        </Fab>
      </Grid>
    )

    return (
      <ButtonBase focusRipple component={Link} to={`/blog/${blog.path}`}>
        <Grid container spacing={2} alignContent="center">
          {arrowPos !== "after" && arrowBtn}
          <Grid item xs>
            <Typography variant="body2">
              <strong>{blog.title}</strong>
            </Typography>
            <Typography variant="overline">
              {DateFnsFormat(new Date(blog.date), NAV_DATE_FORMAT)}
            </Typography>
          </Grid>
          {arrowPos === "after" && arrowBtn}
        </Grid>
      </ButtonBase>
    )
  }

  const tagList = []
  tags.items.forEach((tag: Tag) => {
    tagList.push(tag.tagPK)
  })

  const prevLink = prev ? blogNavLink(prev, "after") : null
  const nextLink = next ? blogNavLink(next, "before") : null

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title} | {data.site.siteMetadata.title}
        </title>
        <meta name="description" content={blurb} />
        <meta name="keywords" content={tagList.join(",")} />
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={5} className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" component="h1">
                  {title}
                </Typography>
                <Typography variant="overline">
                  {DateFnsFormat(publishedDate, DATE_FORMAT)} &middot;{" "}
                  {DateFnsFormat(publishedDate, TIME_FORMAT)}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={12}>
                <ReactMarkdown
                  source={body}
                  escapeHtml={false}
                  renderers={MarkdownRenderers()}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={12}>
                <TagList tags={tags} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignContent="flex-start"
            className={classes.navigation}
          >
            <Grid item xs={12} md={3}>
              {nextLink}
            </Grid>
            <Grid item xs={12} md={3} className={classes.prevLinkContainer}>
              {prevLink}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
